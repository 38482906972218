import React, { useState, useEffect, useCallback } from 'react';
import photos from "../data/Breakfast.json";


export function CuartosGalleryBF() {
  const [selectedPhoto, setSelectedPhoto] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(null);

  // Handle opening a photo
  const openPhoto = (index) => {
    setSelectedPhoto(photos[index]);
    setCurrentIndex(index);
  };

  // Handle closing the zoomed-in photo
  const closePhoto = () => {
    setSelectedPhoto(null);
    setCurrentIndex(null);
  };

  // Handle navigating to the next photo
  const nextPhoto = useCallback(() => {
    const nextIndex = (currentIndex + 1) % photos.length;
    setCurrentIndex(nextIndex);
    setSelectedPhoto(photos[nextIndex]);
  }, [currentIndex]); // Memoize the function

  // Handle navigating to the previous photo
  const prevPhoto = useCallback(() => {
    const prevIndex = (currentIndex - 1 + photos.length) % photos.length;
    setCurrentIndex(prevIndex);
    setSelectedPhoto(photos[prevIndex]);
  }, [currentIndex]); // Memoize the function



  const toggleCollapse = () => {setIsCollapsed(!isCollapsed);};
  const [windowWidth, setWindowWidth] = useState(window.innerWidth); // To track window width
  const [initialVisibleCount, setInitialVisibleCount] = useState(6); // Default initial visible count


  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth); // Update window width when resized
    };

    // Add event listener on mount
    window.addEventListener('resize', handleResize);

    // Clean up the event listener on unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (windowWidth <= 576) {
      setInitialVisibleCount(2); 
    } else if (windowWidth <= 768) {
      setInitialVisibleCount(3);
    } else if (windowWidth <= 992) {
      setInitialVisibleCount(4);
    } else {
      setInitialVisibleCount(4); 
    }
  }, [windowWidth]); // Re-run whenever windowWidth changes

  const [isCollapsed, setIsCollapsed] = useState(true); // State to toggle collapsed view

  // Add keyboard event listeners for Arrow keys and Escape
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (!selectedPhoto) return; // Only listen for keys if the modal is open

      switch (event.key) {
        case 'ArrowLeft':
          prevPhoto(); // Move to the previous photo
          break;
        case 'ArrowRight':
          nextPhoto(); // Move to the next photo
          break;
        case 'Escape':
          closePhoto(); // Close the modal when Escape is pressed
          break;
        default:
          break;
      }
    };

    // Add the event listener on mount
    window.addEventListener('keydown', handleKeyDown);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
}, [selectedPhoto, prevPhoto, nextPhoto]); // Only include memoized functions



  return (
    <div className="gallery">
      <div className="photo-grid">
        {photos.slice(0, isCollapsed ? initialVisibleCount : photos.length).map((photo, index) => (
          <div key={photo.id} className="photo-item" onClick={() => openPhoto(index)}>
            <img src={`/images/${photo.src}`} alt={photo.description} />
          </div>
        ))}
      </div>

      {selectedPhoto && (
        <div className="modal">
          <div className="modal-content">
            <button className="close-btn" onClick={closePhoto}>✖</button>
            <div className="image-container">
              <img src={`/images/${selectedPhoto.src}`} alt={selectedPhoto.description} />
            </div>
            <button className="nav-btn left" onClick={prevPhoto}><span>&#10688;</span></button>
            <button className="nav-btn right" onClick={nextPhoto}><span>&#10689;</span></button>
          </div>
        </div>
      )}
      {photos.length > initialVisibleCount && (
        <button className="show-more-btn" style={{backgroundColor:"white", color:"black"}} onClick={toggleCollapse}>
          {isCollapsed ? "Mostrar Mais" : "Mostrar Menos"}
        </button>
      )}
    </div>
  );
};

