// ExcelReviews.js
import React, { useEffect, useState } from 'react';
import * as XLSX from 'xlsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import bookingC from '../assets/Nosotros/Booking.png'
import verifiedC from '../assets/Nosotros/Verified.png'

export function ExcelReviews() {
  const [reviews, setReviews] = useState([]);
  const [error, setError] = useState(null);

  // Function to format the date
const formatDate = (dateString) => {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};

  const renderStars = (score) => {
    const starCount = Math.floor(score / 2);
    const hasHalfStar = score % 2 !== 0; // Check if there's a half star
    const stars = [];

  // Add full stars
  for (let i = 0; i < starCount; i++) {
    stars.push(<FontAwesomeIcon key={`full-${i}`} icon='fa-solid fa-star' color="gold" />);
  }

  // Add half star if applicable
  if (hasHalfStar) {
    stars.push(<FontAwesomeIcon key="half" icon='fa-solid fa-star-half' color="gold" />);
  }

  return stars;
  };

  useEffect(() => {
    const fetchExcel = async () => {
      try {
        const response = await fetch('/reviews.xlsx'); // Adjust the path as needed
        const data = await response.arrayBuffer();
        
        const workbook = XLSX.read(data); // Read the workbook
        const firstSheetName = workbook.SheetNames[0]; // Get the first sheet
        const worksheet = workbook.Sheets[firstSheetName]; // Get the sheet

        // Convert sheet to JSON
        const json = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
        
        // Filter the header row and map to objects
        const header = json[0]; // First row as header
        const allReviews = json.slice(1)
          .map((row) => {
            const entry = {};
            header.forEach((col, index) => {
              entry[col] = row[index]; // Create object using headers
            });
            return entry;
          })
          .filter((entry) => entry['Nombre del huésped'] && entry['Comentario positivo']); // Filter for valid entries

        // Sort reviews by date (newest first) and limit to the latest 10
        const sortedReviews = allReviews
          .sort((a, b) => new Date(b['Fecha del comentario']) - new Date(a['Fecha del comentario']))
          .slice(0, 10);

        setReviews(sortedReviews);
      } catch (err) {
        setError(err.message);
      }
    };

    fetchExcel();
  }, []);

  if (error) return <p style={{ color: 'red' }}>Error: {error}</p>;

  return (
    <div className="d-flex flex-wrap gap-3 justify-content-center">
      {reviews.length > 0 ? (
        reviews.map((review, index) => (
          <div key={index} className="col-lg-3 col-md-5 col-sm-11 reviewcontainer">
            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex flex-column">
                <h3 className="card-title">{review['Nombre del huésped'] || 'N/A'}</h3>
                <h6>{formatDate(review['Fecha del comentario'] || 'N/A')}</h6>
              </div>
              <div className="">
                <img src={bookingC} alt="" style={{width:'45px'}}/>
              </div>
            </div>
            <div className="">
                {renderStars(Number(review['Puntaje del comentario'])) || 'N/A'}
                <img src={verifiedC} alt="" style={{width:'20px'}} className='ms-2'/>
            </div>
            <div className="mt-3">
              <p className="">{review['Comentario positivo'] || 'N/A'}</p>
            </div>
          </div>
        ))
      ) : (
        <p>No se encontraron comentarios válidos.</p>
      )}
    </div>
  );
};
