import { Container } from "react-bootstrap";
import { Footer } from "../components/7Footer";
import FrenteHostel from '../assets/Cuartos/Hostel2.jpg'
import { CuartosGalleryBF } from "../components/2CuartosGalleryBF";
import { CuartosCards } from "../components/2CuartosCards";



export function Cuartos() {


  return (
    <>
      <div className="" style={{ height: "75vh"}}> 
        <img src={FrenteHostel} alt="" className="Banner" style={{objectPosition:"bottom"}}/>
      </div>
      <div className="" style={{backgroundColor:'white'}}>
        <div className="mb-2">
          <h1 className="shadows-into-light-two-regular tituloBanner" style={{transform:"skew(-20deg)"}}>DESFRUTE DO NOSSO CAFÉ DA MANHÃ INCLUSO!</h1>
          <CuartosGalleryBF></CuartosGalleryBF>
        </div>
        <div className="position-relative" style={{background: 'linear-gradient(282deg, rgba(223,219,171,.5) 28%, rgba(216,208,114,.5) 51%, rgba(223,219,171,.5) 67%, rgba(87,208,155,.5) 80%, rgba(34,210,190,.5) 93%)'}}> 
            <div className="position-absolute w-100 h-50 z-0">  
              <svg id="visual" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 900.2 70.32" width="100%" height="100%">
                  <path d="M.25,0c38.43,28.36,77.93,63.88,127.77,66.96,29.62.77,56.68-12.81,83.79-23.5,17.11-6.91,34.8-13.29,53.25-15.49,20.92-2.36,41.95-.01,62.63,3.11,19.44,2.87,38.85,6.23,58.45,6.88,59.42,1.46,115.64-21.79,174.37-27.34,75.15-7.73,105.05,14.27,169.74,42.24,18,7.36,36.8,13.31,56.35,13.9,19.45.74,38.97-2.33,57.83-7.52,18.92-5.1,37.11-12.67,55.62-19.28,0,0,.14.37.14.37,0,0-27.34,11.04-27.34,11.04-27.41,10.98-56.58,19.22-86.36,18.51-19.92-.35-39.32-6.2-57.69-13.45-27.36-10.83-53.27-25.26-81.29-34.31-76.88-25.31-154.89,9.74-231.88,16.45-9.68.81-19.81,1.06-29.55.78-19.7-.78-39.12-4.28-58.59-7-20.56-2.97-41.5-4.86-62.16-2.11-18.2,2.53-35.41,9.19-52.28,16.29-27.17,11.21-55.01,24.82-85.18,23.71-30.07-1.51-57.3-16.68-81.48-33.48C31.8,26.68,13.94,11.36,0,.32,0,.32.25,0,.25,0H.25Z"/>
              </svg>   
            </div>
            <div className="position-absolute w-100 h-50 bottom-0 z-0">
              <svg id="visual" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 900.14 80.21" width="100%" height="100%">
                  <path d="M0,42.08C83.1,18.11,169.31-6.62,256.64,1.62c28.92,2.86,57.29,9.22,85.88,14.15,23.76,4.26,47.78,6.88,71.93,7.27,33.84.71,68.03-.36,101.22,7.77,46.9,11.44,89.07,38.29,137.39,43.94,48.13,5.27,95.41-10.38,140.06-26.88,18.05-6.8,35.97-14,53.73-21.6,17.77-7.56,35.39-15.5,53.13-23.17,0,0,.17.36.17.36-34.64,16.5-70.24,33.49-105.9,47.33-36.1,13.94-73.33,26.92-112.37,29.05-50.41,3.63-93.81-20.27-139.53-37.2-18.01-6.82-36.6-11.73-55.72-14.1-23.91-3.17-48.12-3.11-72.21-4.01-53-.65-105.37-15.8-158.01-20.12C179.14-1.38,102.65,16.48,28.21,35.36c-9.35,2.42-18.73,4.78-28.11,7.11l-.1-.39h0Z"/>
              </svg>    
            </div>
            <Container className="py-5 gap-3 justify-content-center d-flex flex-column">
                <h3 className="subTituloBanner shadows-into-light-two-regular">Reserve pelo whatsapp e obtenha <u>5%</u> de desconto!</h3>
                <CuartosCards></CuartosCards>
            </Container>
        </div>
      </div>
      <Footer/>
    </>
  );
}