import Nav from 'react-bootstrap/Nav';
import { NavLink } from "react-router-dom"
import { Button } from "react-bootstrap";
import FotoRental from '../assets/Hostel/Rental.jpg'
import FotoYoga from '../assets/Hostel/Yoga.jpg'
import FotoBar from '../assets/Hostel/Bar.jpg'
import FotoMasajes from '../assets/Hostel/Masajes.jpg'




export function HostelCards() {

    return(
        <div className='hostelCardsGrid'>
            <div className="hostelBanner hostelBanner1" data-aos="fade-up">
                        <div className="hostelBannerFoto">
                            <img src={FotoRental} alt="" />
                        </div>
                        <div className="hostelBannerInfo">
                                <h2 className="shadows-into-light-two-regular">SURF</h2>
                                <Button variant="dark">
                                    <Nav.Link to={"https://api.whatsapp.com/send?phone=+5548998172913&text=Quero%20mais%20informações%20sobre%20Surf%20em%20Floresce"} as={NavLink}>Consulte!</Nav.Link>
                                </Button>
                        </div>
            </div>
            <div className="hostelInfo hostelInfo1 shadows-into-light-two-regular">
                    <p>No Floresce, você tem todo o equipamento para surfar as melhores ondas de Rosa, consulte sobre aulas de surf e fotos profissionais.</p>
            </div>
            <div className="hostelBanner hostelBanner2" data-aos="fade-up">
                        <div className="hostelBannerFoto">
                            <img src={FotoYoga} alt="" />
                        </div>
                        <div className="hostelBannerInfo">
                                <h2 className="shadows-into-light-two-regular">YOGA</h2>
                                <Button variant="dark">
                                    <Nav.Link to={"https://api.whatsapp.com/send?phone=+5548998172913&text=Quero%20mais%20informações%20sobre%20Yoga%20em%20Floresce"} as={NavLink}>Consulte!</Nav.Link>
                                </Button>
                        </div>
            </div>
            <div className="hostelInfo hostelInfo2 shadows-into-light-two-regular">
                    <p>Conecte-se com seu eu interior, pratique yoga no Floresce (opção de aulas)</p>
            </div>
            <div className="hostelBanner hostelBanner3" data-aos="fade-up">
                        <div className="hostelBannerFoto">
                            <img src={FotoMasajes} alt="" />
                        </div>
                        <div className="hostelBannerInfo">
                                <h2 className="shadows-into-light-two-regular">MASSAGENS</h2>
                                <Button variant="dark">
                                    <Nav.Link to={"https://api.whatsapp.com/send?phone=+5548998172913&text=Quero%20mais%20informações%20sobre%20Massagens%20em%20Floresce"} as={NavLink}>Consulte!</Nav.Link>
                                </Button>
                        </div>
            </div>
            <div className="hostelInfo hostelInfo3 shadows-into-light-two-regular">
                    <p>Relaxe com massagens profissionais descontraturantes, conectado com a natureza</p>
            </div>
            <div className="hostelBanner hostelBanner4" data-aos="fade-up">
                        <div className="hostelBannerFoto">
                            <img src={FotoBar} alt="" />
                        </div>
                        <div className="hostelBannerInfo">
                                <h2 className="shadows-into-light-two-regular">BAR</h2>
                                <Button variant="dark">
                                    <Nav.Link to={"https://api.whatsapp.com/send?phone=+5548998172913&text=Quero%20mais%20informações%20sobre%20Bar%20em%20Floresce"} as={NavLink}>Consulte!</Nav.Link>
                                </Button>
                        </div>
            </div>
            <div className="hostelInfo hostelInfo4 shadows-into-light-two-regular">
                    <p>Desfrute de <u>Cervejas geladas</u>, <u>Caipirinhas</u> e <u>Comida</u> no nosso Bar</p>
            </div>
    </div>
    )
}