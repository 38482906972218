import { Footer } from "../components/7Footer";
import { HomeCarousel } from "../components/1HomeCarousel";


export function Home() {

  return (
    <>
        <div className="" style={{width: "100vw", height: "100vh"}}><HomeCarousel/></div>
        <Footer/>
    </>
      );
}
