import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import App from './App.js';
import { BrowserRouter } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css'; // Import AOS CSS

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDVvvzV_1s-BOArwG5-mOHkYJvUamBX6VE",
  authDomain: "florescehostel.firebaseapp.com",
  projectId: "florescehostel",
  storageBucket: "florescehostel.appspot.com",
  messagingSenderId: "653913385427",
  appId: "1:653913385427:web:a21f2fbd8dd94667ed2726",
  measurementId: "G-LP7TGB5KBB"
};

AOS.init({
  duration: 800,  // Animation duration
  once: false,      // Trigger animations only once
  offset: 200,     // Offset (in pixels) before the animation starts
});

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
);