import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { NavLink } from "react-router-dom"
import logo from '../assets/Logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInstagram, faYoutube, faWhatsapp, faFacebookF} from '@fortawesome/free-brands-svg-icons'


export function NavBar() {


  return (
    <Navbar expand="lg" variant="" className="bg-body-tertiary" fixed='top' style={{opacity:'0.99'}}>
      <Container>
            <Nav>
                <Nav.Link to="/" as={NavLink} className='p-0'>
                    <div className="d-flex align-items-center" >
                        <img alt=""
                            src={logo}
                            width="70px"/>
                    </div>
                </Nav.Link>
            </Nav>
            <Nav className='mx-5 d-none d-sm-block'>
                <a href='https://www.instagram.com/florescecasahostel/' className="text-reset">
                    <FontAwesomeIcon icon={faInstagram} size='xl' className='navLink'/>
                </a>
                <a href='https://www.facebook.com/profile.php?id=100063617201368' className="text-reset">
                    <FontAwesomeIcon icon={faFacebookF} size='xl'className='ms-3 navLink'/>
                </a>
                <a href='https://www.youtube.com/channel/UC5Q8XRziTC4sJO7Y8q98IyA' className="text-reset">
                    <FontAwesomeIcon icon={faYoutube} size='xl'className='ms-3 navLink'/>
                </a>
                <a href='https://wa.me/message/7FPU6PEAZUZNA1' className="text-reset">
                    <FontAwesomeIcon icon={faWhatsapp} size='xl'className='ms-3 navLink'/>
                </a>
            </Nav>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto" style={{fontSize:"18px"}}>
            <div>
                <ul className='navbar-nav'>
                  <Nav.Link to="/cuartos" as={NavLink} className='navLink'>Quartos</Nav.Link>
                  <Nav.Link to="/hostel" as={NavLink} className='navLink'>Floresce</Nav.Link>
                  <Nav.Link to="/surftrips" as={NavLink} className='navLink'>SurfTrips</Nav.Link>
                  <Nav.Link to="/aboutus" as={NavLink} className='navLink'>Nós</Nav.Link>
                </ul>
            </div>
            <div className="navBarSocial">
            <a href='https://www.instagram.com/florescecasahostel/' className="text-reset">
                    <FontAwesomeIcon icon={faInstagram} size='xl' className='navLink'/>
                </a>
                <a href='https://www.facebook.com/profile.php?id=100063617201368' className="text-reset">
                    <FontAwesomeIcon icon={faFacebookF} size='xl'className='ms-3 navLink'/>
                </a>
                <a href='https://www.youtube.com/channel/UC5Q8XRziTC4sJO7Y8q98IyA' className="text-reset">
                    <FontAwesomeIcon icon={faYoutube} size='xl'className='ms-3 navLink'/>
                </a>
                <a href='https://wa.me/message/7FPU6PEAZUZNA1' className="text-reset">
                    <FontAwesomeIcon icon={faWhatsapp} size='xl'className='ms-3 navLink'/>
                </a>
            </div>
            </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}