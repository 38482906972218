import { Container } from "react-bootstrap";
import { Footer } from "../components/7Footer";
import { ExcelReviews } from "../components/5Reviews"
import J1 from '../assets/Nosotros/Jus.JPG';
import R1 from '../assets/Nosotros/Rabo.JPG';
import JR1 from '../assets/Nosotros/Rabo-Jus2.jpg';
import Hostel from '../assets/Nosotros/HostelHDR.jpg';


export function AboutUs() {
    return(
        <>
        <div className="" style={{ height: "75vh"}}> 
            <img src={JR1} alt="" className="Banner"/>
        </div>
        <div className="">
            <div className=""> 
                <div className="flex-lg-row aboutContainer">
                    <div className="aboutusphoto">
                        <img src={Hostel} alt="" className="object-fit-cover w-100" style={{borderRadius:"10px"}}/>
                    </div>
                    <div className="">
                        <h1 className="tituloBanner shadows-into-light-two-regular" style={{transform:"skew(-20deg)"}}>CASA HOSTEL</h1>
                        <p className="">Estamos localizados em Praia do Rosa, a poucas quadras do centrinho e a 20 minutos a pé de Rosa Norte. <br></br>
                        Nosso alojamento Casa Hostel conta com um espaço de uso comum, um bar atendido por nós, amplo jardim e área coberta, estacionamento privado gratuito, WiFi gratuito, banheiro e cozinha compartilhada.
                        </p>
                    </div>
                </div>
            </div>
            <div className="aboutContainer2">
                <Container className="aboutUs flex-md-row">
                        <div className="" data-aos="fade-up">
                            <h2 className="">Justo Zimmermann</h2>
                            <img src={J1} alt=""/>
                            <p style={{transform:"skew(-15deg)"}}>Cansado da vida corporativa e da grande cidade, Justo chegou a Rosa em busca de relaxar e se conectar com a natureza</p>
                        </div>
                        <div className="" data-aos="fade-up">
                            <h2 className="">Agustin Rabino</h2>
                            <img src={R1} alt=""/>
                            <p style={{transform:"skew(-15deg)"}}>Marplatense que se apaixonou pelo surf e decidiu se mudar para Rosa para viver a boa vida</p>                    
                        </div>
                </Container>
            </div>
        </div>
        <div className="aboutContainer3">
            <Container className="p-3 d-flex flex-column gap-2 align-items-center justify-content-center">
                <div className="locacionRosa">
                    <h4 className="subTituloBanner shadows-into-light-two-regular">Estamos localizados na melhor área de Rosa!</h4>
                    <p style={{transform:"skew(-15deg)"}}>A poucos metros do centrinho e a apenas 20 minutos a pé da praia</p>
                </div>
                <div className="d-flex mx-auto">
                    <iframe className="mapFlo" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d731.4558329185373!2d-48.65015220972898!3d-28.1241570515405!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9526cd5c43e2ce83%3A0x97cc257b1129bd2e!2sCasa%20Hostel%20Floresce!5e0!3m2!1ses-419!2sar!4v1727287068003!5m2!1ses-419!2sar" title="mapFloresce" width="700" height="500" style={{border:'0'}} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                </div>
            </Container>
        </div>
        <div className="aboutContainer4">
                <h3 className="subTituloBanner shadows-into-light-two-regular">Ouça o que dizem sobre nós!</h3>
                <div className="aboutReviews" data-aos="fade-up"> 
                    <ExcelReviews/>
                </div>
        </div>
        <Footer/>
        </>
    )
}