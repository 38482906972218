import { Card, CardSubtitle, Badge } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ammenities from "../data/AmmenitiesHostel.json";


export function HostelAmmenities() {

    return(
        <div className="d-flex flex-wrap justify-content-center gap-1">

            {ammenities.map((ammenity) => {
                if (ammenity.new === 'FALSE') { return (
                    <Card style={{ width: '10rem', height: '10rem', borderColor: 'white'}} className="py-5 px-3" key={ammenity.id}>
                        <FontAwesomeIcon icon={ammenity.FA} size="2xl" />
                        <CardSubtitle className="m-auto pt-2" style={{textAlign:"center"}}>{ammenity.name}</CardSubtitle>            
                    </Card>
                    )
                } else { return (
                    <Card style={{ width: '10rem', height: '10rem', borderColor: 'white'}} className="py-5 px-3" key={ammenity.id}>
                        <div className="d-flex flex-column position-relative">
                            <FontAwesomeIcon icon={ammenity.FA} size="2xl" />
                            <Badge bg="secondary" className="position-absolute top-0 start-50 translate-middle-y">Nuevo</Badge>
                        </div>
                        <CardSubtitle className="m-auto pt-2" style={{textAlign:"center"}}>{ammenity.name}</CardSubtitle>                     
                    </Card>
                    )
                }}
                )}

        </div>
  );
}