import { NavBar } from "./components/6NavBar.jsx";
import { Routes, Route } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Home } from "./views/Home.jsx";
import { Cuartos } from "./views/Cuartos.jsx";
import { AboutUs } from "./views/AboutUs.jsx";
import { Hostel } from "./views/Hostel.jsx";
import { SurfTrips } from "./views/SurfTrips.jsx";
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { faStar, faStarHalf, faMugHot, faWifi, faCarSide, faShirt, faFireBurner, faWineGlass, faParking, faLocationDot, faWater, faSpa, faCamera, faHandsPraying } from "@fortawesome/free-solid-svg-icons";
import './assets/Styles/main.scss';

library.add(fab, faStar, faStarHalf, faMugHot, faWifi, faCarSide, faShirt, faFireBurner, faWineGlass, faParking, faLocationDot, faWater, faSpa, faCamera, faHandsPraying)

function App() {

  return (
    <div style={{minWidth:"240px"}}>
        <Helmet>
          {/* General Meta Tags */}
            <title>Hostel Praia do Rosa - Hospedagem, Surf, Yoga e Mais no Brasil</title>
            <meta name="description" content="Hospede-se em nosso hostel em Praia do Rosa, Brasil. Aproveite Floresce casa hostel e natureza, as piscinas naturais, aulas de surf e yoga, e muito mais em um ambiente relaxante e acessível" />
            <meta name="keywords" content="Hostel, Casa, Casa Hostel, Praia do Rosa, Rosa, Brasil, Reveillon, Praia, Sol, Hospedagem, Surf, Yoga, Aluguel, Centrinho, localização, verão, inverno, outono, primavera, voluntariado, digital, nômade, baleias, natureza, atlântico, piscinas naturais, aulas, fotos, fotografia, barato, café da manhã, relax, relaxamento" />
            
            {/* Open Graph Meta Tags (for social media sharing) */}
            <meta property="og:title" content="Floresce Casa Hostel Praia do Rosa - Hospedagem, Surf, Yoga e Mais no Brasil" />
            <meta property="og:description" content="Hospede-se em nosso hostel em Praia do Rosa, Brasil. Aproveite Floresce casa hostel e natureza, as piscinas naturais, aulas de surf e yoga, e muito mais em um ambiente relaxante e acessível" />
            <meta property="og:url" content="https://www.florescecasahostel.com" />
            <meta property="og:type" content="website" />
            

            {/* Canonical Link */}
            <link rel="canonical" href="https://www.florescecasahostel.com" />
        </Helmet>
        <NavBar/>
        <Routes>
          <Route path="/" element={<Home/>} />
          <Route path="/hostel" element={<Hostel/>} />
          <Route path="/cuartos" element={<Cuartos/>} />
          <Route path="/surftrips" element={<SurfTrips/>} />
          <Route path="/aboutus" element={<AboutUs/>} />
          <Route path="*" element={404} />
        </Routes>
    </div>
  );
}

export default App