import { Container, Stack} from "react-bootstrap";
import { HostelCards } from "../components/3HostelCards";
import { HostelAmmenities } from "../components/3HostelAmmenities";
import { HostelGalleryEXP } from "../components/3HostelGalleryEXP";
import { Footer } from "../components/7Footer";
import SillonInterior from '../assets/Hostel/SillonInterior2.jpg'



export function Hostel() {

  return (
    <>
      <div className="" style={{ height: "75vh"}}> 
        <img src={SillonInterior} alt="" className="Banner"/>
      </div>
      <div className="" style={{backgroundColor:'white'}}>
        <Container className="mt-4">
          <h1 className="shadows-into-light-two-regular tituloBanner" style={{transform:"skew(-20deg)"}}>ESTAMOS PREPARADOS PARA A SUA ESTADIA!</h1>
        </Container>
        <Stack gap={3}>
          <Container className="">
            <div className="p-2 gap-3"><HostelAmmenities/></div>
          </Container>
        </Stack>
        <div className="position-relative" style={{background: 'linear-gradient(90deg, rgba(194,243,255,0.65) 0%, rgba(107,255,207,0.45) 100%)'}}> 
            <div className="position-absolute w-100 h-25">   
              <svg id="visual" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 900 79.82" width="100%" height="100%">
                  <path d="M0,53.92c19.3,3.97,38.56,8.22,58,11.31,19.44,3.1,39.06,5.17,58.7,5.41,19.65.33,39.24-1.68,58.69-4.61,19.46-2.89,38.84-6.53,58.41-9.26,9.78-1.38,19.62-2.48,29.49-3.15,9.87-.69,19.74-.98,29.6-1.26,19.69-.57,39.38-.94,58.77-3.71,9.68-1.37,19.27-3.32,28.64-6.04,9.38-2.65,18.5-6.22,27.51-10.1,18.03-7.73,35.6-16.8,54.27-23.69,9.33-3.42,18.97-6.21,28.89-7.71,4.96-.74,9.99-1.16,15.03-1.1,5.04.05,10.09.58,15.03,1.56,9.89,1.95,19.33,5.49,28.32,9.73,9.02,4.23,17.63,9.17,26.09,14.28,16.9,10.26,33.15,21.43,50.32,30.87,4.3,2.34,8.63,4.61,13.07,6.64,4.42,2.06,8.98,3.8,13.54,5.51,9.16,3.33,18.7,5.56,28.35,6.92,19.33,2.7,39.11,1.35,58.28-2.62,19.2-4.02,37.83-10.5,55.98-18.03,18.16-7.54,35.9-16.14,53.31-25.33,8.71-4.6,17.33-9.35,25.92-14.19l25.8-14.43c-17.03,9.91-33.9,20.1-51.22,29.55-17.3,9.47-34.93,18.35-53.05,26.21-18.15,7.75-36.85,14.44-56.22,18.69-19.34,4.19-39.44,5.77-59.22,3.2-9.87-1.29-19.65-3.49-29.05-6.8-4.68-1.7-9.34-3.49-13.85-5.57-4.53-2.06-8.94-4.35-13.3-6.71-17.41-9.51-33.73-20.65-50.55-30.79-8.41-5.05-16.94-9.9-25.78-14.02-8.82-4.13-18-7.53-27.47-9.36-4.73-.92-9.52-1.41-14.33-1.45-4.81-.04-9.64.37-14.41,1.1-9.57,1.47-18.94,4.22-28.1,7.59-18.37,6.71-35.93,15.7-54.14,23.45-9.1,3.88-18.38,7.49-27.98,10.17-9.57,2.74-19.34,4.7-29.17,6.06-19.68,2.75-39.5,3.06-59.18,3.57-9.85.24-19.67.54-29.47,1.14-9.79.58-19.56,1.58-29.31,2.86-19.5,2.54-38.9,5.99-58.46,8.71-19.53,2.75-39.3,4.63-59.08,4.07-19.77-.54-39.42-2.92-58.84-6.32-9.71-1.7-19.38-3.66-29.01-5.78-9.62-2.12-19.22-4.38-28.82-6.57Z"/>
              </svg>  
            </div>
            <div className="position-absolute w-100 h-50">  
              <svg id="visual" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 900.2 70.32" width="100%" height="100%">
                  <path d="M.25,0c38.43,28.36,77.93,63.88,127.77,66.96,29.62.77,56.68-12.81,83.79-23.5,17.11-6.91,34.8-13.29,53.25-15.49,20.92-2.36,41.95-.01,62.63,3.11,19.44,2.87,38.85,6.23,58.45,6.88,59.42,1.46,115.64-21.79,174.37-27.34,75.15-7.73,105.05,14.27,169.74,42.24,18,7.36,36.8,13.31,56.35,13.9,19.45.74,38.97-2.33,57.83-7.52,18.92-5.1,37.11-12.67,55.62-19.28,0,0,.14.37.14.37,0,0-27.34,11.04-27.34,11.04-27.41,10.98-56.58,19.22-86.36,18.51-19.92-.35-39.32-6.2-57.69-13.45-27.36-10.83-53.27-25.26-81.29-34.31-76.88-25.31-154.89,9.74-231.88,16.45-9.68.81-19.81,1.06-29.55.78-19.7-.78-39.12-4.28-58.59-7-20.56-2.97-41.5-4.86-62.16-2.11-18.2,2.53-35.41,9.19-52.28,16.29-27.17,11.21-55.01,24.82-85.18,23.71-30.07-1.51-57.3-16.68-81.48-33.48C31.8,26.68,13.94,11.36,0,.32,0,.32.25,0,.25,0H.25Z"/>
              </svg>   
            </div>
            <div className="position-absolute w-100 h-25 bottom-0">
              <svg id="visual" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 900 113.23" width="100%" height="100%">
                  <path d="M0,31c19.34,2,38.66,4.27,58.04,5.73,19.38,1.47,38.81,2.26,58.22,1.89,38.83-.38,77.3-7.73,116.47-7.28,9.77.14,19.57.8,29.27,2.15,9.7,1.35,19.29,3.36,28.77,5.74,18.95,4.81,37.41,11.18,55.74,17.69,18.33,6.54,36.55,13.25,55.07,19.02,18.51,5.8,37.23,10.96,56.11,15.38,18.88,4.47,37.91,8.25,57.07,11.32,19.16,3.06,38.42,5.44,57.75,6.46,19.33,1.05,38.74.62,57.91-1.82,19.18-2.41,38.04-7.01,56.49-12.93,18.46-5.89,36.52-13.06,54.38-20.69,17.86-7.63,35.54-15.73,53.19-23.89,17.66-8.13,35.27-16.38,52.84-24.71L900,0c-34.74,17.49-69.6,34.74-104.68,51.56-17.57,8.35-35.19,16.61-53.01,24.43-17.83,7.82-35.89,15.19-54.43,21.3-9.27,3.05-18.64,5.82-28.15,8.12-9.51,2.27-19.15,4.05-28.85,5.32-19.41,2.53-39.04,3.02-58.54,2.03-19.51-.97-38.91-3.31-58.17-6.32-19.26-3.03-38.41-6.76-57.39-11.21-18.97-4.51-37.76-9.75-56.34-15.63-18.6-5.87-36.87-12.66-55.15-19.24-18.28-6.56-36.63-12.94-55.37-17.77-9.37-2.4-18.84-4.38-28.39-5.79-9.54-1.42-19.18-2.16-28.85-2.39-38.74-.83-77.36,6.18-116.4,6.17-19.49.07-38.95-1.02-58.33-2.79-9.69-.89-19.36-1.94-29.01-3.12L0,31Z"/>
              </svg>     
            </div>
            <div className="position-absolute w-100 h-50 bottom-0">
              <svg id="visual" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 900.14 80.21" width="100%" height="100%">
                  <path d="M0,42.08C83.1,18.11,169.31-6.62,256.64,1.62c28.92,2.86,57.29,9.22,85.88,14.15,23.76,4.26,47.78,6.88,71.93,7.27,33.84.71,68.03-.36,101.22,7.77,46.9,11.44,89.07,38.29,137.39,43.94,48.13,5.27,95.41-10.38,140.06-26.88,18.05-6.8,35.97-14,53.73-21.6,17.77-7.56,35.39-15.5,53.13-23.17,0,0,.17.36.17.36-34.64,16.5-70.24,33.49-105.9,47.33-36.1,13.94-73.33,26.92-112.37,29.05-50.41,3.63-93.81-20.27-139.53-37.2-18.01-6.82-36.6-11.73-55.72-14.1-23.91-3.17-48.12-3.11-72.21-4.01-53-.65-105.37-15.8-158.01-20.12C179.14-1.38,102.65,16.48,28.21,35.36c-9.35,2.42-18.73,4.78-28.11,7.11l-.1-.39h0Z"/>
              </svg>    
            </div>
            <div className="position-absolute w-100 h-100">     
              <svg id="visual" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 900 73.33" width="100%" height="100%">
                  <path d="M0,0l26.02,12.59c8.68,4.18,17.41,8.26,26.25,12.07,17.67,7.63,35.78,14.3,54.38,19.09,9.29,2.41,18.71,4.31,28.23,5.45,9.51,1.17,19.1,1.71,28.7,1.84,19.21.27,38.42-1.11,57.7-1.9,9.64-.4,19.31-.66,28.99-.52,9.69.11,19.37.83,28.99,1.84,19.25,2.05,38.29,5.4,57.26,8.71,18.98,3.3,37.94,6.57,57.04,8.43,19.09,1.84,38.31,2.35,57.42,1.08,19.11-1.2,38.09-4.31,56.56-9.31,9.24-2.47,18.32-5.55,27.35-8.83,9.03-3.28,18.01-6.75,27.03-10.17,9.03-3.41,18.11-6.72,27.35-9.64,9.24-2.9,18.63-5.49,28.24-7.11,9.6-1.64,19.41-2.39,29.17-1.79,9.76.59,19.38,2.4,28.77,4.84,18.77,5.01,36.69,12.32,54.73,18.99,18.03,6.63,36.36,12.74,55.45,14.9,19.04,2.37,38.39,1.86,57.48-.41,9.55-1.14,19.06-2.71,28.51-4.56l28.36-5.61-28.24,6.2c-9.43,2.01-18.93,3.73-28.49,5.02-19.12,2.58-38.57,3.41-57.84,1.31-2.4-.31-4.81-.56-7.21-.94l-7.16-1.24-7.1-1.56c-2.36-.53-4.69-1.21-7.03-1.81-9.34-2.57-18.5-5.67-27.59-8.92-18.15-6.52-36.09-13.62-54.64-18.39-9.27-2.32-18.71-3.99-28.19-4.47-9.48-.52-19,.21-28.39,1.85-9.4,1.61-18.63,4.19-27.76,7.08-9.13,2.92-18.14,6.24-27.13,9.66-8.99,3.44-17.97,6.95-27.05,10.27-9.07,3.32-18.25,6.47-27.62,9.01-18.72,5.12-37.95,8.35-57.31,9.61-19.36,1.22-38.8.64-58.06-1.28-19.28-1.93-38.32-5.28-57.3-8.64-18.97-3.38-37.92-6.77-57-8.86-9.54-1.05-19.1-1.75-28.69-1.94-9.59-.23-19.2-.06-28.81.24-19.23.61-38.52,1.81-57.86,1.36-9.67-.22-19.34-.86-28.94-2.13-9.61-1.26-19.11-3.24-28.44-5.79-18.66-5.11-36.73-12.09-54.31-20.01-8.8-3.96-17.47-8.17-26.08-12.5L0,0Z"/>
              </svg>
            </div>
            <Container className="py-5">
                <h3 className="tituloBanner shadows-into-light-two-regular">VIVA A AVENTURA FLORESCE!!</h3>
                <div className="p-2 d-flex justify-content-center">
                  <HostelCards/>
                </div>
            </Container>
        </div>
        <div className="py-3">
          <h2 className="tituloBanner shadows-into-light-two-regular">Conheça e compartilhe no Floresce Casa Hostel</h2>
          <HostelGalleryEXP></HostelGalleryEXP>
        </div>
      </div>
      <Footer/>
    </>
  );
}