import { Container, Button } from "react-bootstrap";
import { Footer } from "../components/7Footer";
import Nav from 'react-bootstrap/Nav';
import { NavLink } from "react-router-dom"
import hospedaje from '../assets/SurfTrips/Iconos/hospedaje.png'
import comida from '../assets/SurfTrips/Iconos/comida.png'
import surfing from '../assets/SurfTrips/Iconos/surfboard.png'
import traslado from '../assets/SurfTrips/Iconos/traslado.png'
import ST from '../assets/SurfTrips/SurfTripBanner.jpg'
import { SurftripCarouselPlayas } from "../components/4SurftripCarouselPlayas";




export function SurfTrips() {

    return(
        <>
        <div className="" style={{ height: "75vh"}}> 
            <img src={ST} alt="" className="Banner" style={{objectFit:"bottom"}}/>
        </div>
        <Container className="p-3 mt-2">
            <div className="surfContainer">
                <div className="d-flex">
                    <div className=""></div>
                    <div className="surfTripTitulo">
                        <h2 className="subTituloBanner">UMA SEMANA DE MUITO SURFE!</h2>
                        <h5 className="">Montamos uma viagem sob medida para você, com opções de 4 a 7 noites</h5>
                    </div>
                </div>
                <div className="surfAmmenities" >
                    <div className="" data-aos="fade-up">
                        <div className="">
                            <img src={comida} alt="" />
                        </div>
                        <h5 className="shadows-into-light-two-regular">COMIDA</h5>
                    </div>
                    <div className="" data-aos="fade-up">
                        <div className="">
                            <img src={hospedaje} alt="" />
                        </div>
                        <h5 className="shadows-into-light-two-regular">HOSPEDAGEM</h5>
                    </div>
                    <div className="" data-aos="fade-up">
                        <div className="">
                            <img src={surfing} alt="" />
                        </div>
                        <h5 className="shadows-into-light-two-regular">SURFING</h5>
                    </div>
                    <div className="" data-aos="fade-up">
                        <div className="">
                            <img src={traslado} alt="" />
                        </div>
                        <h5 className="shadows-into-light-two-regular">TRASLADOS</h5>
                    </div>
                </div>
            </div>
        </Container>
        <Container className="my-1 p-3">
            <div className="surfContainer2">
                <h3 className="tituloBanner shadows-into-light-two-regular" 
                style={{
                position:"relative",
                left:"-10%",
                top:"35px",
                transform:"skew(-20deg)"
                }}>DINÂMICA PROPOSTA DA VIAGEM</h3>
                <div className="row justify-content-start ps-3" style={{backgroundImage:`url(${require("../assets/SurfTrips/Dinamica1.jpg")}`}}>
                    <div className="col-12 col-md-4 d-flex flex-column justify-content-between">
                        <h5>Café da manhã - Surfe</h5>
                        <h6 className="">Ao amanhecer, após o café da manhã, aproveitamos os primeiros raios de sol para surfar bem cedo em uma praia à escolha</h6>
                    </div>
                </div>
                <div className="row justify-content-center" style={{backgroundImage:`url(${require("../assets/SurfTrips/Dinamica2.jpg")}`}}>
                    <div className="col-12 col-md-4 d-flex flex-column justify-content-between">
                    <h5>Almoço - Relax</h5>
                    <h6 className="">Passado o meio-dia, voltamos para o Hostel para um almoço nutritivo, que será seguido de um tempo de relaxamento e descanso</h6>
                    </div>
                </div>
                <div className="row justify-content-end" style={{backgroundImage:`url(${require("../assets/SurfTrips/Dinamica3.jpg")}`}}>
                    <div className="col-12 col-md-4 d-flex flex-column justify-content-between">
                    <h5>Surfe (FDT Rosa) - Jantar</h5>
                    <h6 className="">Voltamos para Rosa para o fim de tarde na água e depois desfrutamos de um delicioso jantar gourmet para repor as energias para o dia seguinte</h6>
                    </div>
                </div>
                <Button className="align-self-center my-3" variant="dark" style={{fontWeight:"500", fontSize:"1.8rem"}}>
                    <Nav.Link to="https://api.whatsapp.com/send?phone=+5548998172913&text=Quero%20mais%20informações%20sobre%20os%20Surftrips" as={NavLink}>Consulte!</Nav.Link>
                </Button>
            </div>
        </Container>
        <div className="position-relative" style={{background: 'linear-gradient(90deg, rgba(194,243,255,0.65) 0%, rgba(107,255,207,0.45) 100%)'}}> 
            <div className="position-absolute w-100 h-100">     
              <svg id="visual" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 900 73.33" width="100%" height="100%">
                  <path d="M0,0l26.02,12.59c8.68,4.18,17.41,8.26,26.25,12.07,17.67,7.63,35.78,14.3,54.38,19.09,9.29,2.41,18.71,4.31,28.23,5.45,9.51,1.17,19.1,1.71,28.7,1.84,19.21.27,38.42-1.11,57.7-1.9,9.64-.4,19.31-.66,28.99-.52,9.69.11,19.37.83,28.99,1.84,19.25,2.05,38.29,5.4,57.26,8.71,18.98,3.3,37.94,6.57,57.04,8.43,19.09,1.84,38.31,2.35,57.42,1.08,19.11-1.2,38.09-4.31,56.56-9.31,9.24-2.47,18.32-5.55,27.35-8.83,9.03-3.28,18.01-6.75,27.03-10.17,9.03-3.41,18.11-6.72,27.35-9.64,9.24-2.9,18.63-5.49,28.24-7.11,9.6-1.64,19.41-2.39,29.17-1.79,9.76.59,19.38,2.4,28.77,4.84,18.77,5.01,36.69,12.32,54.73,18.99,18.03,6.63,36.36,12.74,55.45,14.9,19.04,2.37,38.39,1.86,57.48-.41,9.55-1.14,19.06-2.71,28.51-4.56l28.36-5.61-28.24,6.2c-9.43,2.01-18.93,3.73-28.49,5.02-19.12,2.58-38.57,3.41-57.84,1.31-2.4-.31-4.81-.56-7.21-.94l-7.16-1.24-7.1-1.56c-2.36-.53-4.69-1.21-7.03-1.81-9.34-2.57-18.5-5.67-27.59-8.92-18.15-6.52-36.09-13.62-54.64-18.39-9.27-2.32-18.71-3.99-28.19-4.47-9.48-.52-19,.21-28.39,1.85-9.4,1.61-18.63,4.19-27.76,7.08-9.13,2.92-18.14,6.24-27.13,9.66-8.99,3.44-17.97,6.95-27.05,10.27-9.07,3.32-18.25,6.47-27.62,9.01-18.72,5.12-37.95,8.35-57.31,9.61-19.36,1.22-38.8.64-58.06-1.28-19.28-1.93-38.32-5.28-57.3-8.64-18.97-3.38-37.92-6.77-57-8.86-9.54-1.05-19.1-1.75-28.69-1.94-9.59-.23-19.2-.06-28.81.24-19.23.61-38.52,1.81-57.86,1.36-9.67-.22-19.34-.86-28.94-2.13-9.61-1.26-19.11-3.24-28.44-5.79-18.66-5.11-36.73-12.09-54.31-20.01-8.8-3.96-17.47-8.17-26.08-12.5L0,0Z"/>
              </svg>
            </div>
            <Container className="surfContainer3">
                <h2 className="tituloBanner shadows-into-light-two-regular">CONHEÇA AS ONDAS DE IMBITUBA</h2>
                <div className="p-2 d-flex justify-content-center mx-auto">
                     <SurftripCarouselPlayas></SurftripCarouselPlayas>
                </div>
            </Container>
        </div>
        <Footer></Footer>
        </>
    );
}