import React, { useState, useEffect } from 'react';
import { CuartosCarouselComp } from "../components/2CuartosCarouselComp";
import { CuartosCarouselCasal } from "../components/2CuartosCarouselCasal";
import { NavLink } from "react-router-dom"
import Nav from 'react-bootstrap/Nav';
import { Button} from "react-bootstrap";


export function CuartosCards() {

  const [isModalVisible1, setIsModalVisible1] = useState(false);
  const openModal1 = () => {
    setIsModalVisible1(true);
  };
  const closeModal1 = () => {
    setIsModalVisible1(false);
  };

  const [isModalVisible, setIsModalVisible] = useState(false);
  const openModal = () => {
    setIsModalVisible(true);
  };
  const closeModal = () => {
    setIsModalVisible(false);
  };

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
        <div className="cuartosTarjeta">
                    <div className="cuartosTarjetaFotos">
                        <CuartosCarouselComp></CuartosCarouselComp>
                        <Button className="cuartosMasInfoButton" onClick={openModal1} variant='light'>Mais Info</Button>
                    </div>
                    <div className="cuartosTarjetaInfo">
                        <h2 className="shadows-into-light-two-regular tituloBanner" style={{fontWeight:"500", letterSpacing:"0px", width:"100%"}}>Quarto Compartilhado</h2>
                        <div className="cuartosTarjetaInfoDetalle">   
                          <div className="cuartosTarjetaInfoLI" style={windowWidth <= 576 ? isModalVisible1 ? {display:"flex"} : {display:"none"} : {display:"flex"}}>
                            <span className="masInfoClose" onClick={closeModal1}>&times;</span>
                            <p className="masInfoClose1">  Cama beliche</p>
                            <p className="masInfoClose1">  Ventilador</p>
                            <p className="masInfoClose1">  Estante</p>
                            <p className='masInfoClose2'> Cama beliche, ventilador, estante</p>
                          </div>
                          <div className="cuartosTarjetaButtons">
                            <Button variant="dark">
                                <Nav.Link to={"https://www.booking.com/hotel/br/casahostel-floresce.es-ar.html"} as={NavLink}>BOOKING</Nav.Link>
                            </Button>
                            <Button style={{backgroundColor:"#25d366", borderColor:"#25d366"}}>
                                <Nav.Link to={"https://api.whatsapp.com/send?phone=+5548998172913&text=Quero%20mais%20informações%20sobre%20um%20lugar%20em%20um%20quarto%20compartilhado"} as={NavLink}>WhatsApp</Nav.Link>
                            </Button>
                          </div>
                        </div>
                    </div>
        </div>
        <div className="cuartosTarjeta cuartosTarjeta2">
                    <div className="cuartosTarjetaInfo">
                        <h2 className="shadows-into-light-two-regular tituloBanner" style={{fontWeight:"500", letterSpacing:"0px", width:"100%"}}>Quarto Privativo</h2>
                        <div className="cuartosTarjetaInfoDetalle">   
                            <div className="cuartosTarjetaInfoLI" style={windowWidth <= 576 ? isModalVisible ? {display:"flex"} : {display:"none"} : {display:"flex"}}>
                                <span className="masInfoClose" onClick={closeModal}>&times;</span>
                                <p className='masInfoClose1'>Cama de casal</p>
                                <p className='masInfoClose1'>Estantes</p>
                                <p className='masInfoClose1'>Ar Condicionado (consultar)</p>
                                <p className='masInfoClose1'>Varanda (consultar)</p>
                                <p className='masInfoClose2'>Cama de casal, estantes, Ar Condicionado (consultar), Varanda (consultar)</p>
                            </div>
                            <div className="cuartosTarjetaButtons">
                                <Button variant="dark">
                                  <Nav.Link to={"https://www.booking.com/hotel/br/casahostel-floresce.es-ar.html"} as={NavLink}>BOOKING</Nav.Link>
                                </Button>
                                <Button style={{backgroundColor:"#25d366", borderColor:"#25d366"}}>
                                  <Nav.Link to={"https://api.whatsapp.com/send?phone=+5548998172913&text=Quero%20mais%20informações%20sobre%20um%20lugar%20em%20um%20quarto%20privativo"} as={NavLink}>WhatsApp</Nav.Link>
                                </Button>
                            </div>
                        </div>
                    </div>
                    <div className="cuartosTarjetaFotos" style={{borderRadius:'30px'}}>
                        <CuartosCarouselCasal></CuartosCarouselCasal>
                        <Button className="cuartosMasInfoButton" onClick={openModal} variant='light'>Mais Info</Button>
                    </div>
        </div>
    </>
  );
}