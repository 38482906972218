import { useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import praia from "../data/SurftripCarouselPlayas.json";


export function SurftripCarouselPlayas() {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  const imgStyle = {
    width: "100%",  
    height: "100%", 
    objectFit: "cover", 
  }

  return (
    <Carousel activeIndex={index} onSelect={handleSelect} indicators={false} className="ConoceOlas">
      {praia.map((foto) => {return(
        <Carousel.Item className='position-relative' style={imgStyle} key={foto.id}>
          <img alt='' src={`/images/${foto.foto}`} style={{  width: "100%",  height: "100%", objectFit: "cover" }}/>
          <div className="SurfTripOlas">
            <h3 className='shadows-into-light-two-regular'>{foto.name}</h3>
            <p>{foto.description}</p>
          </div>  
        </Carousel.Item>
      )})}
    </Carousel>
  );
}