import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWhatsapp} from '@fortawesome/free-brands-svg-icons'

export function Footer() {
    return(
        <>
            <div className="App">designed by Agustin Rabino
            </div>
            <a href='https://wa.me/message/7FPU6PEAZUZNA1' className="contactWhap">
            <div className="contactWhapInner">
                <FontAwesomeIcon icon={faWhatsapp} className='fa-3x' style={{color: "white"}}/>
            </div>
            </a>
        </>
    );
}