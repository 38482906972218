import { useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import hostel from "../data/HostelCarouselComp.json";


export function CuartosCarouselComp() {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  const imgStyle = {
    width: "100%",  
    height: "100%", 
    objectFit: "cover", 
  }

  return (
    <Carousel activeIndex={index} onSelect={handleSelect} style={{width:'100%', height:'100%'}}>
      {hostel.map((foto) => {return(
        <Carousel.Item style={imgStyle} key={foto.id}>
          <img alt='' src={`/images/${foto.foto}`} style={{  width: "100%",  height: "100%", objectFit: "cover" }}/>
          <Carousel.Caption className='carouselCaption'>
            <h3>{foto.name}</h3>
          </Carousel.Caption>
        </Carousel.Item>
      )})}
    </Carousel>
  );
}